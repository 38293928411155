@import '../../../../../../styles/shared';

:global(.formatted-text) {
  &.text {
    font-size: 16px;
    line-height: 28px;

    p {
      font-size: 16px;
      line-height: 28px;
    }

    * + h2,
    * + h3,
    * + h4,
    * + h5,
    * + h6 {
      margin: 2.5rem 0 1rem;
    }
  }
}

@include hub-dark-theme() {
  :global {
    .formatted-text {
      table {
        thead,
        tfoot {
          th,
          td {
            background-color: var(--hub-module-darker-background-color);
          }
        }
      }
    }
  }
}
